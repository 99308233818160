<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    data-testid="deactivate-patient-app"
  >
    <template #activator="{ on }">
      <v-btn
        rounded
        color="secondary"
        @click="openDialog"
        class="elevation-1"
        v-on="on"
      >
        Deactivate app
      </v-btn>
    </template>

    <v-card color="#F4F8FE">
      <BoxedToolbar
        @close-dialog="closeDialog"
        data-testid="deactivate-patient-app-card-title"
      >
        Deactivate app for patient {{ patientNo }}
      </BoxedToolbar>
      <BoxedCard>
        <SettingsHeader
          :patient-no="patientNo"
          :title="`Deactivate the app for patient ${patientNo}`"
          :show-icon="true"
        >
          <template #icon>
            <ImgDeactivateApp />
          </template>
        </SettingsHeader>

        <v-form v-model="formValid" ref="editForm">
          <v-row class="no-gutters">
            <v-col class="offset-4 col-5">
              <FormLabel>
                To deactivate the app, please select a reason
              </FormLabel>

              <v-radio-group
                v-model="deactivateReason"
                :rules="deactivateReasonRules"
                data-testid="select-app-deactivate-reason"
                class="boxed-radio-group"
                hide-details
                @change="
                  addAuditEntry('PATIENT_APP:REASON_FOR_DEACTIVATE', $event)
                "
                ><span
                  v-for="(option, index) in deactivateReasonOptions"
                  :key="index"
                  class="radio-option"
                  :class="{
                    selected: deactivateReason === option.value,
                  }"
                >
                  <v-radio
                    :label="option.text"
                    :value="option.value"
                    :data-testid="`version-reason-option-${option.value}`"
                  />
                </span>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-slide-y-reverse-transition :hide-on-leave="true">
            <v-row v-if="deactivateReason === 'Other'" no-gutters class="">
              <v-col class="offset-4 col-7">
                <v-textarea
                  counter
                  outlined
                  class="mt-2 reason-text rounded-lg spacing-2"
                  rows="4"
                  :auto-grow="true"
                  v-model="deactivateReasonProvided"
                  :rules="reasonProvidedRules"
                  :maxlength="reasonProvidedMaxLength"
                  background-color="white"
                  placeholder="Please provide a reason"
                  @change="
                    addAuditEntry('PATIENT_APP:REASON_FOR_DEACTIVATE', $event)
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-slide-y-reverse-transition>

          <v-row class="no-gutters mt-8 pr-4">
            <v-col class="text-right">
              <v-btn
                @click="closeDialog"
                text
                class="btn-cancel-reset-pin mr-4"
                data-testid="btn-cancel"
                ><v-icon>mdi-chevron-left</v-icon> Cancel & return to
                settings</v-btn
              >
              <v-btn
                @click="showConfirmDialog = true"
                :disabled="!formValid"
                :loading="isLoading"
                color="secondary"
                rounded
                class="elevation-1"
                data-testid="btn-continue"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="apiError">
            <v-col class="offset-4 col-8">
              <v-alert type="error" dense text class="mb-0">{{
                apiError
              }}</v-alert>
            </v-col>
          </v-row>
        </v-form>
      </BoxedCard>
      <v-dialog v-model="showConfirmDialog" max-width="479" persistent>
        <DialogCard @closeDialog="showConfirmDialog = false">
          <template #title> Confirm the following change </template>
          <template #actions>
            <v-btn
              rounded
              depressed
              @click="showConfirmDialog = false"
              class="mr-4 elevation-1"
              data-testid="btn-cancel"
              >Cancel</v-btn
            >
            <v-btn
              color="error"
              rounded
              depressed
              @click="executeRequest"
              :loading="isLoading"
              class="elevation-1"
              data-testid="btn-deactivate-app"
              >Deactivate app</v-btn
            >
          </template>
          <p>
            <span class="d-block">App settings</span>
            <strong>Deactivate app for patient {{ patientNo }}</strong>
          </p>
          <p>
            <span class="d-block">Reason for deactivation</span>
            <strong>{{ getDeactivateReason }}</strong>
          </p>
        </DialogCard>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import SettingsHeader from '../settings/_SettingsHeader'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel'
import DialogCard from '@/components/DialogCard'
import ImgDeactivateApp from '@/components/images/ImgDeactivateApp'

import loadingState from '@/constants/loadingState'
import AuditLog from '@/utils/audit/AuditLog'
import service from '@/services/app-service'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import appState from '@/constants/appState'
import { notificationMapActions } from '@/store/modules/notificationModule'
import NotificationType from '@/store/modules/notification/NotificationType'
import BoxedToolbar from '@/components/layout/BoxedToolbar'
import BoxedCard from '@/components/patient/settings/BoxedCard'
import reasonOtherValidationMixin from '@/components/mixins/reasonOtherValidationMixin'

const initialState = () => ({
  deactivateReason: null,
  deactivateReasonProvided: null,
  state: loadingState.INITIAL,
  auditLog: new AuditLog(),
  apiError: null,
})

export default {
  name: 'DeactivateAppSteps',
  mixins: [reasonOtherValidationMixin],
  components: {
    BoxedCard,
    BoxedToolbar,
    DialogCard,
    ImgDeactivateApp,
    FormLabel,
    SettingsHeader,
  },
  props: {
    patientNo: { type: String, required: true },
  },
  data() {
    return {
      ...initialState(),
      formValid: false,
      showDialog: false,
      showConfirmDialog: false,
      loadingState,
      deactivateReasonOptions: [
        { value: 'Lost phone', text: 'Lost phone' },
        { value: 'App not working', text: 'App not working' },
        { value: 'Other', text: 'Other' },
      ],
      deactivateReasonRules: [v => !!v || 'A reason is required'],
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
    getDeactivateReason() {
      return this.deactivateReason === 'Other'
        ? this.deactivateReasonProvided
        : this.deactivateReason
    },
  },
  methods: {
    ...notificationMapActions(),
    openDialog() {
      if (this.$refs.editForm) this.$refs.editForm.reset()

      Object.assign(this.$data, initialState())
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    executeRequest() {
      this.apiError = null
      this.state = loadingState.LOADING

      const reason = this.getDeactivateReason

      service
        .deleteApp({
          patientNumber: this.patientNo,
          reason,
          currentClientTime: dateTimeWithTimeZone(),
          auditEntries: this.auditLog.getAuditEntries(),
        })
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED

          if (response.state === appState.ACTIVATED) {
            this.spawnNotification({
              type: NotificationType.Error,
              title: 'Deactivation',
              message: `The service didn't deactivate the device`,
              timeToLive: 10000,
            })
          }

          this.$emit('app-deactivated') //hmmmm - what is it isn't
          this.closeDialog()
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED

          this.apiError = error
          this.showConfirmDialog = false

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
}
</script>
